<template>
  <div class="ParametersLogos">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="upload-logo col mb-4 pb-4">
            <form @submit.prevent="uploadLogo()">
              <div class="row">
                <div class="col-12 col-xl-8">
                  <div class="row">
                    <div class="col-6">
                      <div class="mb-1 info-label">{{ $t('PARAMETERS_UPLOAD_HINT') }}</div>
                      <div class="form-group">
                        <input type="file" id="logo-upload-btn" class="form-control-file" @change="loadFile($event)" />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="mb-1 info-label">{{ $t('PARAMETERS_LOG_TEXT_HINT') }}</div>
                      <div class="logo-label-input form-group">
                        <input type="text" v-model="logoLabel" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <button class="Button Button__edit" :disabled="logo2Upload === null || !logoLabel" type="submit">
                        {{ $t('ACTION_UPLOAD') }}
                      </button>
                    </div>
                    <div class="col-10">
                      <div class="error-msg pt-2" v-if="error">{{ $t(error) }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div v-if="logosItems.length === 0">
            {{ $t('NO_ITEMS_FOUND') }}
          </div>
          <table class="table table-striped table-bordered" v-if="logosItems.length > 0">
            <thead>
              <th>{{ $t('PARAMETERS_LOG_TABLE_HEAD_LOGO') }}</th>
              <th>{{ $t('PARAMETERS_LOG_TABLE_HEAD_LABEL') }}</th>
              <th></th>
            </thead>
            <tbody>
              <tr v-for="logo in logosItems" :key="logo.id" :class="{ 'is-logos-deleting': isLogosDeleting(logo.id) }">
                <td style="vertical-align: middle;">
                  <img style="max-height: 80px;" :src="logo.path" />
                </td>
                <td style="vertical-align: middle;">
                  {{ logo.label }}
                </td>
                <td style="width: 20%; vertical-align: middle;">
                  <div v-if="!isLogosDeleting(logo.id)">
                    <button class="Button Button__delete" @click="setLogosDeleting(logo.id, true)">
                      <Delete /> {{ $t('ACTION_DELETE') }}
                    </button>
                  </div>
                  <div v-if="isLogosDeleting(logo.id)">
                    <div class="confirm">
                      <span>{{ $t('DELETE_CONFIRM_QUESTION') }}</span>
                      <button class="cursor-pointer" @click="deleteLogo(logo.id)">{{ $t('YES') }}</button>
                      <button class="cursor-pointer" @click="setLogosDeleting(logo.id, false)">{{ $t('NO') }}</button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Delete from 'vue-material-design-icons/Delete.vue'

export default {
  name: 'ParametersLogos',
  data() {
    return {
      logo2Upload: null,
      error: null,
      maxFileSize: 2500,
      acceptedMimes: ['image/gif', 'image/jpeg', 'image/png'],
      logoLabel: ''
    }
  },
  components: {
    Delete
  },
  computed: {
    ...mapGetters(['logosItems', 'isLogosDeleting'])
  },
  methods: {
    setLogosDeleting(logo, value) {
      this.$store.dispatch('LOGOS_SET_IS_DELETING', { logo, value })
    },
    deleteLogo(logo) {
      this.$store.dispatch('LOGOS_DELETE', logo)
    },
    loadFile(event) {
      this.error = ''
      var input = event.target
      if (input.files && input.files[0] && input.files.length > 0) {
        if (!this.acceptedMimes.includes(input.files[0].type)) {
          this.error = 'CFG_ERROR_FILENOTSUPPORTED'
          return
        }
        if (input.files[0].size / 1024 < this.maxFileSize) {
          var FR = new FileReader()
          FR.addEventListener('load', e => {
            if (e !== undefined) {
              this.logo2Upload = { type: input.files[0].type, data: e.target.result }
            }
          })
          FR.readAsDataURL(input.files[0])
        } else {
          this.error = 'CFG_ERROR_FILESIZE'
          return
        }
      }
    },
    uploadLogo() {
      if (this.logo2Upload && this.logo2Upload.type && this.logo2Upload.data) {
        this.$store.dispatch('LOGOS_UPLOAD', { label: this.logoLabel, logo: this.logo2Upload }).then(() => {
          this.logo2Upload = null
          this.logoLabel = ''
          document.getElementById('logo-upload-btn').value = ''
        })
      }
    }
  },
  created() {
    this.$store.dispatch('LOGOS_GET_LIST')
  }
}
</script>

<style scoped lang="scss">
.ParametersLogos {
  padding: 30px 0;
  .upload-logo {
    border-bottom: 1px solid $c-grey;

    .info-label {
      color: $c-blue;
    }

    .logo-label-input {
      border: 1px solid $c-grey;
      width: 100%;
      input {
        height: 28px;
        width: 100%;
      }
    }
  }
  #logo-upload-btn {
    border: 1px solid $c-grey !important;
    height: 31px;
    width: 100%;
  }
  .error-msg {
    color: $c-error;
  }

  button {
    width: 100% !important;
    &[disabled] {
      background: $c-grey;
      border-color: $c-grey;
      cursor: default;
    }
  }

  .is-logos-deleting {
    background: rgba($c-black, 0.7) !important;
    .confirm {
      color: $c-white !important;
      span {
        display: block;
      }
      button {
        width: 35% !important;
        font-size: 18px;
        color: $c-white;
        padding: 0 10px;
        margin-left: 15px;
        background: transparent;
        border: 1px solid $c-white;
      }
    }
  }
}
</style>
